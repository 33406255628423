import {
  DX_AUTH_UI,
  OHW_BASE_URL_TRIMMED,
  queryClientProps,
  ASSETS_URI,
  AUTOCOMPLETE_URL,
} from '../../utils/env-vars';
import { getServerSideClients } from '@dx-ui/framework-react-query';
import type {
  StaticOfferQuery,
  BrandOfferListingQuery as BrandStaticOfferOptionsQuery,
} from '@dx-ui/content-offers-components';
import {
  OfferDetails,
  OfferListing,
  serverSideBrandOfferListingQuery,
  serverSideStaticOfferQuery,
} from '@dx-ui/content-offers-components';

import Head from 'next/head';
import { serverSideTranslations } from '../../utils/server-side-translations';
import { getOfferIdFromSlug, slugifyOfferName } from '../../utils/slugify-offer';
import { OffersHeader } from '../../components/header';
import { OffersFooter } from '../../components/footer';
import { useRouter } from 'next/router';
import { ViewDetailsLink } from '../../components/view-details';
import type { PageMetricsDetail, PageMetricsListing } from '../../components/analytics';
import { Analytics } from '../../components/analytics';
import { getBrandCanonical } from '../../utils/get-brand-canonical';
import { removeQs } from '../../utils/remove-qs';
import { serverSideNavigationQuery } from '../../generated/react-query';
import type { GetServerSideProps } from 'next';
import { dehydrate } from '@tanstack/react-query';
import { getServerSideShopFormData } from '@dx-ui/osc-shop-form';

export default function OfferSlugPage(props: {
  brandCode?: string;
  brandName?: string;
  brandUrl?: string;
  isBrandPage?: boolean;
  offerId?: number;
  seo?:
    | NonNullable<NonNullable<BrandStaticOfferOptionsQuery['brand']>['page']>['seo']
    | NonNullable<NonNullable<StaticOfferQuery['staticOffer']>['seo']>;
  metrics: PageMetricsListing | PageMetricsDetail;
  brandSlug?: string;
}) {
  const { locale, query, asPath } = useRouter();
  const brandCode = props.brandCode || 'WW';
  const { seo, brandUrl, brandName, metrics } = props;
  return (
    <>
      <Analytics metrics={metrics} />
      <Head>
        <title>{seo?.pageTitle}</title>
        <meta name="keywords" content={seo?.metaKeywords || ''} />
        <meta name="description" content={seo?.metaDescription || ''} />
        {seo?.hideSearchEngine ? <meta name="robots" content="noindex" /> : null}
      </Head>
      <OffersHeader
        brandCode={brandCode}
        brandUrl={brandUrl}
        brandName={brandName}
        language={locale}
      />
      {props.offerId && (
        <OfferDetails
          offerId={props.offerId}
          language="en"
          brandCode={brandCode}
          autocompleteUri={AUTOCOMPLETE_URL}
          baseUrl={OHW_BASE_URL_TRIMMED}
          signInIframeUrl={DX_AUTH_UI.replace('__LANG__', locale)}
          renderAdditionalComponents={(data) => {
            const path =
              data?.staticOffer?.type === 'brand'
                ? `/${locale}/offers/${slugifyOfferName(data?.staticOffer)}/`
                : asPath;
            const canonicalUrl = removeQs(path);

            return (
              <Head>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@Hilton" />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={seo?.pageTitle || ''} />
                <meta property="og:description" content={seo?.metaDescription || ''} />
                <meta property="og:site_name" content="Hilton" />
                <meta property="og:image" content={data.firstImageForOg} />
              </Head>
            );
          }}
        />
      )}
      {props.isBrandPage && (
        <div className="container mx-auto my-4">
          <Head>
            <link rel="canonical" href={removeQs(`/${locale}${asPath}`)} />
          </Head>
          <OfferListing
            language={locale as string}
            brandCode={brandCode}
            assetUrl={ASSETS_URI}
            useBrandTheme={true}
            renderAdditionalComponents={(data) => {
              return (
                <Head>
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:site" content="@Hilton" />
                  <meta property="og:url" content={removeQs(`/${locale}${asPath}`)} />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={seo?.pageTitle || ''} />
                  <meta property="og:description" content={seo?.metaDescription || ''} />
                  <meta property="og:site_name" content="Hilton" />
                  <meta property="og:image" content={data.firstImageForOg} />
                </Head>
              );
            }}
            renderInternalOfferLink={(offer) => (
              <ViewDetailsLink
                offer={offer}
                hrefPrefix={`/${locale}/offers/${(query.slug as string[])?.join('/')}/`}
              />
            )}
          />
        </div>
      )}
      <OffersFooter brandCode={brandCode} language={locale || 'en'} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async function getServerSideProps({
  query,
  locale = 'en',
  res,
  req,
}) {
  const envVars = queryClientProps;
  const [possibleBrandName, possibleOfferId] = query.slug as string[];

  const featureToggleHeader = req.headers['dx-toggles'] as string;

  const { queryClient } = getServerSideClients({
    ...envVars,
    response: res,
    appName: 'dx-offers-ui',
    customHeaders: {
      'dx-toggles': featureToggleHeader,
    },
  });
  const brandMatches = await getBrandCanonical({
    queryClient,
    requestedBrandName: possibleBrandName,
    language: locale,
  });

  if (brandMatches?.redirectBrandSlug) {
    const offerId = possibleOfferId;
    return {
      redirect: {
        destination: `/${locale}/offers/${brandMatches.redirectBrandSlug}/${
          offerId ? `${offerId}/` : ''
        }`,
        permanent: true,
      },
    };
  }
  await getServerSideShopFormData(queryClient, { getTranslateAutocomplete: true }).catch((e) => {
    console.error('Error fetching serverSideShopFormData', e); //eslint-disable-line no-console
  });
  await serverSideNavigationQuery(queryClient, {
    language: locale,
    brandCode: brandMatches?.brandCode || 'WW',
  });

  if (brandMatches && !possibleOfferId) {
    // this is a brand listing page, not an offer detail page
    const brandOfferListingResult = await serverSideBrandOfferListingQuery(queryClient, {
      brandCode: brandMatches.brandCode,
      language: locale || 'en',
    });

    const seo = brandOfferListingResult?.brand?.page?.seo || {};
    const metrics: PageMetricsListing = {
      brandCode: brandMatches.brandCode,
      brandName: brandOfferListingResult?.brand?.formalName || '',
      language: locale,
      primaryCategory: 'Brand',
      trackCallName: 'offerListingPageView',
    };

    return {
      props: {
        ...envVars,
        seo,
        metrics,
        isBrandPage: true,
        brandCode: brandMatches.brandCode,
        brandUrl: brandMatches?.url || null,
        brandName: brandMatches?.formalName || null,
        dehydratedQueryState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
        ...(await serverSideTranslations(locale)),
      },
    };
  }

  // brandName might be the offerId
  const unSlugifiedOfferId = possibleOfferId
    ? getOfferIdFromSlug(possibleOfferId || '')
    : getOfferIdFromSlug(possibleBrandName);

  // try to fetch that offer
  let offerResult: StaticOfferQuery | null = null;
  const offerId = isNaN(Number(unSlugifiedOfferId)) ? null : Number(unSlugifiedOfferId);
  if (offerId) {
    offerResult = await serverSideStaticOfferQuery(queryClient, {
      language: locale,
      offerId,
      includeHotel: false,
      ctyhocn: '',
      brandCode: brandMatches?.brandCode || 'WW',
    }).catch(() => null);
  }

  const brandOfferDetailPage = Boolean(possibleOfferId && possibleBrandName);

  // if that offer is not found redirect back to offers page or brand offers
  if (!offerResult?.staticOffer) {
    const urlParts = [locale, 'offers'];
    if (brandOfferDetailPage) {
      urlParts.push(brandMatches?.redirectBrandSlug || possibleBrandName);
    }
    return {
      redirect: {
        destination: `/${urlParts.join('/')}/`,
        permanent: true,
      },
    };
  }

  // make sure offer name slugified matches what was passed, if not redirect to correct name
  if (
    slugifyOfferName(offerResult.staticOffer) !==
    (brandOfferDetailPage ? possibleOfferId : possibleBrandName)
  ) {
    const urlParts = [locale, 'offers'];
    if (brandOfferDetailPage) {
      urlParts.push(brandMatches?.redirectBrandSlug || possibleBrandName);
    }
    urlParts.push(slugifyOfferName(offerResult.staticOffer));
    return {
      redirect: {
        destination: `/${urlParts.join('/')}/`,
        permanent: true,
      },
    };
  }

  const seo = offerResult?.staticOffer?.seo || {};
  const metrics: PageMetricsDetail = {
    brandCode: 'HI',
    brandName: offerResult?.brand?.formalName || '',
    language: locale,
    primaryCategory: 'Portfolio',
    trackCallName: 'offerDetailPageView',
    offerId: unSlugifiedOfferId,
    offerName: offerResult?.staticOffer?.name,
  };

  // otherwise offer found, this is a single offer page
  return {
    props: {
      ...envVars,
      seo,
      metrics,
      offerId: unSlugifiedOfferId,
      brandCode: brandMatches?.brandCode || 'WW',
      brandUrl: brandMatches?.url || null,
      brandName: brandMatches?.formalName || null,
      dehydratedQueryState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      ...(await serverSideTranslations(locale)),
    },
  };
};
